<template>
    <div>
        <v-card class="primary darken-2" style="" >
            <v-card-text class="py-1 ma-0 " style="font-size: 12px; ">
                <p class="white--text py-1 ma-0 subheading text-xs-center"><span>APPLYING TO JOIN NEW NJANGI GROUP</span></p>
            </v-card-text>
        </v-card>
        <v-divider></v-divider>

        <v-card color="white pa-3" style="" >
            <v-layout row wrap class="">
                <v-flex xs7 class="pa-1">
                    <p class="dark--text subheading pa-0 ma-0 " blue>
                        <b>Modalities:</b> 
                    </p>
                </v-flex>
                <v-flex xs5 class="mt-0 text-xs-right">
                    <v-btn class="white--text" small round color="teal darken-2" :to="{name: 'njangi_groups.new_group_one'}">
                        <span> Get started</span>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <ul>
                <li class="dark--text pt-1 ">You will need to fill in information from an identification document (National ID or Passport)</li>
                <li class="dark--text pt-1 ">Information about your source of income, assets and earnings will be needed.</li>
                <li class="dark--text pt-1 ">The various njangi groups available are <b>Daily</b>, <b>Weekly</b> and <b>Monthly</b>.</li>
                <li class="dark--text pt-1 ">Note that contributions are through MTN MoMo, Orange Money and our office. Any delays in contributions will receive penalties.</li>
            </ul>
        </v-card>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                completedCampaigns: [
                    {
                        title: 'New Telephone',
                        creation_date: '02/02/2022 at 10:43',
                        objective: '30,000 FCFA by the 13/04/2022',
                        link: '/campaigns/1',
                        key: 'xHI654Gik'
                    },
                    {
                        title: 'Christmas',
                        creation_date: '02/02/2022 at 10:43',
                        objective: 'By the 23/12/2022',
                        link: '/campaigns/2',
                        key: 'x#$fg6k'
                    },
                    {
                        title: 'Health Campaign',
                        creation_date: '12/06/2021 at 10:43',
                        objective: '13,500 FCFA',
                        link: '/campaigns/3',
                        key: 'xHI6GfSD'
                    }
                ],
            }
        },

    }
</script>
